<template>
  <div class="headerBox">
    <img src="@/assets/images/3271.png" alt="" />
    <div class="rightText">{{state.nowTime}}</div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      vrUrl: false,
      waterEntityEnd: false,
      captchaImg: "",
      type: "账号登录",
      nowTime: false,
      loginForm: {
        idcard: "",
        password: "",
        code: "",
      },
      is: false,
      loginRules: {
        idcard: [
          { required: true, message: "请填写手机号码", trigger: "change" },
        ],
        password: [
          { required: true, message: "请填写登录密码", trigger: "change" },
        ],
        code: [{ required: true, message: "请填写验证码", trigger: "change" }],
      },
      user: "",
      region: "",
    });
    const checkNum = (num) => {
      if (num < 10) {
        num = "0" + num;
      }
      return num;
    };
    const getNoewTime = () => {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = checkNum(date.getMonth() + 1);
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = checkNum(date.getHours());
      //分 getMinutes()： (0 ~ 59)
      var minute = checkNum(date.getMinutes());
      //秒 getSeconds()：(0 ~ 59)
      var second = checkNum(date.getSeconds());
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        "  " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    };

    onMounted(async () => {
      state.nowTime = getNoewTime();
      setInterval(() => {
        state.nowTime = getNoewTime();
      }, 1000);
    });
    return {
      state,
      //方法
    };
  },
};
</script>
<style lang="less" scoped>
.headerBox {
  z-index: 10;
  background: #6085f1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  // -webkit-box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
  // -moz-box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
  // box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 300px;
  box-sizing: border-box;
  .bigName {
    font-size: 36px;
    color: black;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  .rightText {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
  }
  .rightAllBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 20px;
      margin-left: 10px;
      object-fit: contain;
    }
  }
}
.centertBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .login_frame_div {
    width: 375px;
    background: rgba(51, 153, 255, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 30px 0;
    text-align: center;
    .login_frame_div_title {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #606266;
      margin-bottom: 10px;
    }
    .login_frame_div_text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #606266;
      margin-bottom: 30px;
    }
    .login_frame_div_button {
      font-size: 18px;
      width: 300px;
      height: 50px;
      box-shadow: 0px 5px 15px 0px rgba(0, 114, 255, 0.2),
        0px 20px 40px 0px rgba(0, 114, 255, 0.2);
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
  .login_frame_image {
    width: auto;
    height: 100%;
  }
  .login_frame_from {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0 30px;
    .login_frame_from_title {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #606266;
      margin-bottom: 56px;
    }
    .login_frame_from_types {
      width: 338px;
      height: 40px;
      border: 1px solid #dcdcdc;
      // border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;
      .login_frame_from_type {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        z-index: 2;
        cursor: pointer;
        position: relative;
        transition: color 0.5s;
      }
      .login_frame_from_back {
        position: absolute;
        top: 0;
        width: calc(50% - 2px);
        height: calc(100% - 2px);
        // background: rgba(51, 153, 255, 1);
        border: 1px solid rgba(51, 153, 255, 1);
        transition: left 0.5s;
      }
    }
    .login_frame_from_input {
      width: 340px;
      position: relative;
      .login_frame_from_icon {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 20px;
        height: 20px;
        z-index: 9;
        color: rgba(181, 181, 181, 1);
      }
      .login_frame_from_line {
        position: absolute;
        left: 48px;
        top: 13px;
        z-index: 9;
        width: 1px;
        height: 24px;
        background: #dcdcdc;
      }
    }
    .login_frame_from_is {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 340px;
      height: 20px;
      margin-bottom: 10px;
    }
    .login_frame_from_button {
      width: 340px;
      height: 50px;
      font-size: 20px;
    }
    .login_frame_from_regs {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }
}
.footerBox {
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 150px;
  -webkit-box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  -moz-box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  flex-shrink: 0;
}

.color_2 {
  font-size: 18px;
  color: rgba(51, 153, 255, 1);
}
.color_3 {
  font-size: 18px;
  color: black;
}
.allOtherWay {
  width: 338px;
  margin-top: 20px;
  color: #606266;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin: 0 5px;
    object-fit: contain;
  }
}
</style>