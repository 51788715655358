<template>
  <div class="content">
    <Header />
    <div class="noticeBox" v-if="registerShow">
      <img src="@/assets/images/re.png" alt="">
      <div class="allText">
        <div class="bigText">提交申请成功，请耐心等待审核...</div>
        <div class="smallText">审核结果邮箱通知您，请及时查收。</div>
      </div>
    </div>
    <div class="register_from">
      <h1 class="register_from_title size-24">注册个人账号申请</h1>
      <div class="color_3 size-14 line-24 m-b-lg">
        <div>1.填写个人信息：</div>
        <div class="m-l-xs">（1）务必填写正确的手机号码，手机号码将是您的登录帐号；</div>
        <div class="m-l-xs">（2）设置密码；</div>
        <div class="m-l-xs">（3）填写您的真实姓名，点选所属单位、所属部门、所属角色，如果检索不到单位、部门、角色，则表示不在系统中，请联系管理员进行添加（联系电话：13799314040）</div>

        <div>2. 邮箱验证：填写正确邮箱账号，点击“获取验证码”，前往邮箱获取验证码，填写验证码并提交申请。
        </div>
        <div>3. 管理员审核申请：提交申请后，管理员对信息进行审核，审核结果邮箱通知您。</div>
      </div>
      <h1 class="register_from_title size-18">个人信息</h1>
      <el-form label-position="right" label-width="140px" ref="registerForm" class="register_from_obj"
        :model="formLabelAlign" :rules="loginRules">

        <el-form-item label="手机号码：" prop="phone">
          <el-input type="number" v-model="formLabelAlign.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>

        <el-form-item label="输入密码：" prop="password">
          <el-input v-model="formLabelAlign.password" show-password autocomplete="new-password"
            placeholder="密码长度8-20位，由字母和数字组成，区分大小写"></el-input>
        </el-form-item>

        <el-form-item label="确定密码：" prop="confirm_password">
          <el-input show-password autocomplete="new-password" v-model="formLabelAlign.confirm_password"
            placeholder="请再次输入新密码"></el-input>
          <div class="color_4 size-14 line-18 m-l-xs">
            确认密码必须同登录密码一致。
          </div>
        </el-form-item>


        <el-form-item label="实名人员：" prop="realName">
          <el-input v-model="formLabelAlign.realName" placeholder="请输入实名人员姓名"></el-input>
        </el-form-item>


        <el-form-item label="岗位：" prop="postId">
          <el-select v-model="formLabelAlign.postId" placeholder="请选择所在单位（可通过关键字筛选）">
            <el-option types v-for="item in postArr" :key="item + 'hjkljd'" :label="item.postName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属部门：" prop="deptId">
          <el-select v-model="formLabelAlign.deptId" placeholder="请选择所属部门">
            <el-option types v-for="item in deptArr" :key="item + 'hjddd'" :label="item.deptName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属角色：" prop="roleId">
          <el-select v-model="formLabelAlign.roleId" placeholder="请选择所属系统角色">
            <el-option types v-for="item in roleArr" :key="item + 'hjggd'" :label="item.roleName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="绑定系统：" prop="appIds">
          <el-select multiple v-model="formLabelAlign.appIds" placeholder="请选择绑定系统（可多选）">
            <el-option types v-for="item in appArr" :key="item + 'hjhgfd'" :label="item.appName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱号码接收验证码">
          </el-input>
        </el-form-item>
        <el-form-item label="邮箱验证码：" prop="code">
          <el-input v-model="formLabelAlign.code" placeholder="请输入验证码">
            <template #append>
              <el-button class="blue1" type="primary" :disabled="codeStr" @click="getCode">{{
                codeStr? `${codeNumber}s` : "获取验证码"
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>

      </el-form>
      <div class="flex items-center justify-center">
        <el-button type="primary" @click="subRegister()" class="register_button">提交申请</el-button>
        <!-- <el-button type="primary" class="register_button" plain @click="$router.go(-1)">返回</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { ElMessageBox, ElMessage } from "element-plus";
// import { userRegister, getEnterpriseSelectList, getResisterLabel } from "@/api";
// import md5 from "js-md5";
import Header from "./header.vue";
import { registerAccout,postList, deptList, roleList, appsList, sendEmail } from "@/api";
export default {
  components: {
    Header
  },

  name: "register",
  data() {
    return {
      codeStr: false,
      codeNumber: 60,
      postArr: [],
      deptArr: [],
      roleArr: [],
      appArr: [],
      formLabelAlign: {
        phone: "",
        password: "",
        confirm_password: "",
        realName: "",
        email: "",
        code: '',
        roleId: '',
        deptId: "",
        postId: "",
        appIds: ""
      },
      loginRules: {
        phone: [
          { required: true, message: "请填写手机号码", trigger: "change" },
          { validator: this.checkMoblie, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请填写密码", trigger: "change" },
        ],
        confirm_password: [
          { required: true, message: "请再次输入新密码", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入实名人员姓名", trigger: "change" },
        ],
        email: [
          { required: true, message: "请输入电子邮箱", trigger: "change" },
          { validator: this.checkEmail, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
        roleId: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        deptId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        postId: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
        appIds: [
          { required: true, message: "请选择绑定系统", trigger: "change" },
        ],
      },
      registerShow:false
    }
  },
  created() {
    this.getAllList()
  },
  watch: {

  },
  mounted() {

  },
  computed: {

  },
  methods: {
     // 注册用户
     subRegister() {
      // console.log(11121)
      // var data2 = this.clone(this.formLabelAlign)
      // data2.birthday = this.formLabelAlign.birthday.getTime();
      this.$refs.registerForm.validate((valid) => {
        console.log(valid)
        if (!valid) return;
        if (
          this.formLabelAlign.password != this.formLabelAlign.confirm_password
        ) {
          return this.$message({
            type: "warning",
            message: "登录密码与确认密码不一致！",
          });
        }
        var pwdRegex = /^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)[\da-zA-z]{8,20}$/g;

        if (!pwdRegex.test(this.formLabelAlign.password)) {
          return this.$message({
            type: "warning",
            message:
              "密码长度8-20位，密码至少包含包含数字、字母、特殊字符其中两种，区分大小写",
          });
        }
        // console.log(11112)
        // this.formLabelAlign.password = md5(this.formLabelAlign.password);
        // this.formLabelAlign.confirm_password = md5(
        //   this.formLabelAlign.confirm_password
        // );
        // console.log(11112)
        registerAccout(this.formLabelAlign).then((res) => {
          console.log(res)
          if (!res.code) return false;
          this.registerShow = true;
          this.num = 3;
          this.timer = setInterval(() => {
            this.num -= 1;
            if (this.num == 0) {
              clearInterval(this.timer);
              this.$router.push("/login");
              // ElMessageBox.close();
            }
          }, 1000);
          // ElMessageBox.alert(
          //   res.msg + ",(" + this.num + ")秒后转跳到登录页！！",
          //   "提示",
          //   {
          //     confirmButtonText: "确定",
          //     callback: (action) => {
          //       console.log(action);
          //     },
          //   }
          // );
        });
      });
    },
    getCode() {
      if (!this.checkEmail1(this.formLabelAlign.email))
        return this.$message({
          message: "请输入正确的邮箱地址！！！",
          type: "warning",
        });
      this.codeStr = true;
      sendEmail({email:this.formLabelAlign.email}).then(res=>{
        // console.log(res)
      })
      const setIn = setInterval(() => {
        this.codeNumber -= 1;
        if (this.codeNumber == 0) {
          this.codeStr = false;
          this.codeNumber = 60;
          clearInterval(setIn);
        }
      }, 1000);
    },
    getAllList() {
      postList({ current: 1, size: 9999 }).then(res => {

        this.postArr = res.data.records
        // console.log(this.postArr)
      })
      deptList().then(res => {
        this.deptArr = res.data[0].children
        // console.log(this.deptArr)
      })
      roleList().then(res => {
        this.roleArr = res.data
        // console.log(this.roleArr)
      })
      appsList({ current: 1, size: 9999 }).then(res => {
        this.appArr = res.data.records
        // console.log(this.appArr)
      })
    },
    checkEmail1(value) {
      //验证邮箱的正则表达式
      const regEmail =
        // /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        /^([\w-+]|[\w.+])+@[(\w)]+(\.)+([\w]){2,14}$/;
      if (regEmail.test(value)) {
        //合法的邮箱
        return true
      }
      return false
    },
    // 验证邮箱的自定义规则
    checkEmail(rule, value, cb) {
      //验证邮箱的正则表达式
      const regEmail =
        // /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        /^([\w-+]|[\w.+])+@[(\w)]+(\.)+([\w]){2,14}$/;
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb();
      }
      cb(new Error("请输入正确的邮箱"));
    },
    checkMoblie(rule, value, cb) {
      //验证手机号的正则表达式
      const regMobile =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (regMobile.test(value)) {
        //合法的手机号
        return cb();
      }
      cb(new Error("请输入正确的手机号"));
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(240, 240, 240, 1);
  position: relative;
  box-sizing: border-box;
  padding-bottom: 200px;

  .register_from {
    width: 1000px;
    background: #ffffff;
    max-width: 100%;
    padding: 40px 80px;
    box-sizing: border-box;
    margin: 90px auto;

    .register_from_title {
      color: rgba(48, 49, 51, 1);
      padding-bottom: 13px;
      border-bottom: 1px solid rgba(220, 220, 220, 1);
      margin-bottom: 20px;
    }

    .register_from_obj {
      width: 100%;
    }

    .register_button {
      width: 225px;
      font-size: 16px;
      margin-top: 50px;
      background-color: rgba(57, 105, 242, 1) !important;
    }
  }
}

.blue1 {
  background-color: rgba(57, 105, 242, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-radius: 0px 3px 3px 0px !important;
}

.noticeBox {
  width: 1000px;
  height: 160px;
  background: #FFFFFF;
  padding: 60px 60px 50px 60px;
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  box-sizing: border-box;

  img {
    width: 56px;
    height: 60px;
    object-fit: contain;
    margin-right: 30px;
  }

  .allText {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bigText {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #303133;
    }

    .smallText {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #909399;
      line-height: 36px;
    }
  }

}
</style>
